<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-lock"
      color="secondary"
      title="Change Password"
    >
      <v-form
        ref="passwordForm"
        lazy-validation
        @submit.prevent="updatePassword"
      >
        <v-row align="center">
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="prevPassword"
              placeholder="Enter Previous Password"
              prepend-icon="mdi-lock"
              clearable
              :rules="[required]"
            />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="newPassword"
              placeholder="Enter New Password"
              prepend-icon="mdi-lock"
              clearable
              :rules="[required]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              small
              color="primary"
              type="submit"
              width="100%"
            >
              <v-icon left>
                mdi-update
              </v-icon>
              Update Password
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
  import { Api } from '@/apis'

  export default {
    data: () => ({
      required: value => !!value || 'This field is required.',
      prevPassword: '',
      newPassword: '',
      updating: false,
    }),

    methods: {
      async updatePassword () {
        if (this.$refs.passwordForm.validate()) {
          this.updating = true
          await new Api().post({
            url: 'update-password',
            request: {
              new_password: this.newPassword,
              previous_password: this.prevPassword,
            },
          })
          this.updating = false
          this.$refs.passwordForm.reset()
        }
      },
    },
  }
</script>
